// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SELECT */

.ant-select {
  font-family: "-apple-system, BlinkMacSystemFont, 'Segoe UI, PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue, Helvetica, Arial, sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol'", !important;
}

.ant-select-dropdown {
  border: 1px solid #e9e9e9;
  animation-duration: 0s !important;
  z-index: 9999;
}

.ant-select-auto-complete {
  ol,
  ul,
  dl {
    margin: 0;
  }
}
